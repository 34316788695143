import BaseApi from './base'
class AdRetails extends BaseApi {
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response
  }

  async getAdPlanners (params) {
    return await this.auth({
      url: '/ad_planners',
      method: 'GET',
      params : params
    })
  }

  async getItems (ad_id, params) {
    return await this.auth({
      url: `/ad_planner_item/${ad_id}`,
      method: 'GET',
      params: params
    })
  }

  async updateRetailAd(ad_id, item_data){
   return await this.auth({
     url: `/ad_planner/${ad_id}`,
     method: 'PUT',
     data: item_data
    })
  }
}

export default new AdRetails()