import BaseApi from './base'
class Filters extends BaseApi {
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response
  }

  async getFilters (params) {

    return await this.auth({
      url: '/filters',
      method: 'GET',
      params: params
    })
  }

  async createFilter (filter) {

    return await this.auth({
      url: '/filter',
      method: 'POST',
      data: filter
    })
  }

  async updateFilter (filterId, filter) {
    return await this.auth({
      url: `/filter/${filterId}`,
      method: 'PUT',
      data: filter
    })
  }

  async deleteFilter(filterId){
    return await this.auth({
      url: `/filter/${filterId}`,
      method: 'DELETE',
    })
  }
}

export default new Filters()