import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store/index.js'
import vuetify from './plugins/vuetify'

import '@/assets/styles/global.css'

// Axios Files
import AdRetails from '@/axios/ad-retail-endpoints'
import Ads from '@/axios/ad-endpoints'
import Documents from '@/axios/doc-endpoints'
import Features from '@/axios/feature-endpoints'
import Filters from '@/axios/filter-endpoints'
import Items from '@/axios/item-endpoints'
import KeyValues from '@/axios/key-values'
import Layouts from '@/axios/layout-endpoints'
import PartyRelationship from '@/axios/party-relationship-endpoints'
import Party from '@/axios/party-endpoint'
import PromoItem from '@/axios/promotion-item-endpoints'
import PromoItemGroup from '@/axios/promotion-item-group-endpoints'
import Promos from '@/axios/promotion-endpoints'
import Promotions from '@/axios/promotion-endpoints'
import Status from '@/axios/status-endpoints'

// Third Party Libraries
import _ from 'lodash'
import moment from 'moment'

// Local Config
import dateConfig from '@/config/date-config'

// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Auth0
import { domain, clientId, audience } from './auth/auth0-config'
import { Auth0Plugin } from './auth/auth0-plugin'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

const appEnv = process.env.VUE_APP_ENV

if (appEnv !== 'local') {
  Sentry.init({
    Vue: Vue,
    environment: appEnv,
    dsn: 'https://d5fcc17102ae42d081d19c6f5aa64ff8@o461793.ingest.sentry.io/6009320',
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    tracesSampleRate: 1.0
  })
}

Vue.config.productionTip = false

Vue.prototype.$AdRetails = AdRetails
Vue.prototype.$Ads = Ads
Vue.prototype.$Documents = Documents
Vue.prototype.$Features = Features
Vue.prototype.$Filters = Filters
Vue.prototype.$Items = Items
Vue.prototype.$KeyValues = KeyValues
Vue.prototype.$Layouts = Layouts
Vue.prototype.$PartyRelationship = PartyRelationship
Vue.prototype.$Party = Party
Vue.prototype.$PromoItem = PromoItem
Vue.prototype.$PromoItemGroup = PromoItemGroup
Vue.prototype.$Promos = Promos
Vue.prototype.$Promotions = Promotions
Vue.prototype.$Status = Status

Vue.prototype._ = _
Vue.prototype.moment = moment

Vue.prototype.$dateConfig = dateConfig

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
