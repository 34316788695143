import BaseApi from './base'
class layouts extends BaseApi{
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response
  }

  async getLayouts (limit, offset) {
    const params = { limit: limit, offset: offset}

    return await this.auth({
      url: '/layouts',
      method: 'GET',
      params: params
    })
  }

  async getLayoutbyId (layoutId) {
    return await this.auth({
      url: `/layout/${layoutId}`,
      method: 'GET'
    })
  }

  async createLayout (layout) {
    return await this.auth({
      url: `/layout`,
      method: 'POST',
      data: layout
    })
  }

  async createAdFromLayout(layoutId, body){

    return await this.auth({
      url: `/layout/${layoutId}`,
      method: 'POST',
      data: body
    })
  }

  async updateLayout (layoutId, layout) {
    return await this.auth({
      url: `/layout/${layoutId}`,
      method: 'PUT',
      data: layout
    })
  }

  async deleteLayout (layoutId) {
      return await this.auth({
          url: `/layout/${layoutId}`,
          method: 'DELETE',
      })
  }
}

export default new layouts()