import BaseApi from './base'

class PromoItem extends BaseApi{
  constructor () {
    super()
  }

  async getItemsByGroup (promo_item_group_id) {
    const params = {limit:200}
    return this.auth({
      url: `/promo_items/${promo_item_group_id}`,
      method: 'GET',
      params:params
    })
  }

  async getItem (promo_item_id) {
    return this.auth({
      url: `/promo_item/${promo_item_id}`,
      method: 'GET'
    })
  }

  async getItemsByPromo (promo_id, params){
    return this.auth({
      url:`/promo_items/promo/${promo_id}`,
      method:'GET',
      params:params
    })
  }
  
  async promoItemLookup(UUIDs, params) {
    return this.auth({
      url:`/promo_items/lookup`,
      method:'POST',
      params:params,
      data:UUIDs
    })

  }
}

export default new PromoItem()