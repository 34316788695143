// Third Party Helpers
import { format, addMinutes } from 'date-fns'

function formatDate (date) {
  // trying to prevent 'RangeError: Invalid time value'
  if (!date) return
  date = new Date(date)
  // add minutes to offset timezone difference from UTC
  date = addMinutes(date, date.getTimezoneOffset())
  return format(date, dateConfig.date_display)
}

function formatDateTime (date) {
  // trying to prevent 'RangeError: Invalid time value'
  if (!date) return
  date = new Date(date)
  // add minutes to offset timezone difference from UTC
  return format(date, dateConfig.timestamp)
}

const dateConfig = {
  date_display: 'M/D/YYYY',
  date_format: 'YYYY-MM-DD',
  timestamp: 'MMM D - h:mm a',
  currentDate: new Date().toISOString().substr(0, 10),
  formatDate: formatDate,
  formatDateTime: formatDateTime
}




export default dateConfig