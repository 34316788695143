import BaseApi from './base'
class Promos extends BaseApi {
  constructor () {
    super()
  }
  
  async getPromosList () {
    return this.auth({
      url: '/promos',
      method: 'GET',
    })
  }

  async getPromoById (promoId) {
    return this.auth({
      url: `/promo/${promoId}`,
      method: 'GET',
    })
  }
  async getPromoItemGroup (promoId){
    return this.auth({
      url: `/promo_item_groups/${promoId}`,
      method: 'GET',
    })
  }

  async getPromoList(params, data = {}, responsible_party_id){
    return this.auth({
      url:`/promo/promo_preview/${responsible_party_id}`,
      method:'POST',
      params:params,
      data: data
    })
  }
}
export default new Promos()