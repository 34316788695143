<template>
  <v-app id="inspire" v-if="$auth.isAuthenticated">

    <v-app-bar dark color="main" app>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>{{drawer ? 'mdi-backburger' : 'mdi-menu'}}</v-icon>
      </v-app-bar-nav-icon>
        <router-link :to="{ path: '/'}">
          <v-img v-if="tenantsWithLogos.includes(`${$auth.tenant}`)"
            max-height="110"  
            max-width="110"
            class="ml-6"
            :src="require(`@/assets/${$auth.tenant}_logo.png`)" 
              :alt="`${$auth.tenant} Logo`"> 
          </v-img>
          <v-app-bar-nav-icon v-else>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
        </router-link>
      <v-spacer/>
      <v-menu v-if="$auth.tenant === 'awg'" class='mx-15' open-on-hover transition="slide-x-transition" bottom right offset-y :close-on-content-click='false'>
              <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-help-rhombus-outline</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto" width="150" tile>
              <v-list dense>
                <v-subheader>Tutorial Videos</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="link, i in videoLinks"
                    :key='i'
                    @click="component='TestVideo', modal=true, id=link.id"
                    >
                    {{link.label}}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            </v-menu>
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="adplan" />
      </v-col>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="toolbar">
      <v-list nav>
        <v-list-item
          v-for="(link, i) in navLinks"
          :key="i"
          :to="{ path: `${link.path}` }"
          exact link>
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[(!miniVariant) ? 'pa-2': 'pa-1']" style="height:64px;">
          <v-btn block id="logoutBtn" :style="styleObject" class="mt-2" @click.prevent="logout()">
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <AlertBar></AlertBar>
      <router-view v-if="hasPortalAccess" />
      <v-alert
        v-else
        type="error"
        text
        border="left">
        You don't have authorized permissions to access this site.
      </v-alert>

      <v-snackbar multi-line bottom rounded="lg" height="60" transition="slide-y-reverse-transition"
        v-model="snackbar.showing" :timeout="snackbar.timeout" :color="snackbar.color">
        <v-row dense align="center">
          <span>
            <v-icon left>{{snackbar.icon}}</v-icon>
            {{snackbar.text}}
          </span>
        </v-row>
        <v-row dense justify="end">
          <v-btn small v-if="snackbar.status === 'error'" text @click="snackbar.showing = false">
            Close
          </v-btn>
        </v-row>
      </v-snackbar>

    </v-main>
          <Component :key="componentKey" :is="component" :id='id' :value.sync="modal"
        />
    <v-footer color="main" dark app class="justify-space-between">
      <span class="white--text"> iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy; {{ new Date().getFullYear() }} </span>
      <span class="white--text">v. {{ version }}</span>
    </v-footer>

  </v-app>
</template>

<script>
const PortalNavMenu = () => import('@/components/shared/PortalNavMenu.vue')
import AlertBar from '@/components/shared/alert-bar'
import { mapState } from 'vuex'
import { userAccess } from '@/mixins/user-access'

export default {
  name: 'App',

  mixins: [userAccess],

  data: () => ({
    component: '',
    componentKey: 0,
    drawer: false,
    id:'',
    modal: false,
    miniVariant: false,
    styleObject: {
        background: '#E53935',
        color: 'white',
        fontSize: '15px'
      },
  }),

  components: {
    TestVideo: () => import('@/components/ads/test-video'),
    PortalNavMenu,
    AlertBar
  },

  computed: {
    userData () {
      return this.$auth.user
    },

    version () {
      return process.env.VUE_APP_VERSION
    },

    tenantsWithLogos () {
      return [        
        'awg',
        'alliance-retail-group'      
      ]  
    },

    ...mapState(['snackbar']),

    navLinks () {
      return [
        // { icon: 'mdi-home-outline', title: 'Home', path: '/' },
        { icon: 'mdi-newspaper-variant-outline', text: 'Ad Planner', path: '/ads', show: true },
        //REMOVE WHEN AD RETAIL SCREEN IS ENABLED IN IPRO PORTAL
        // { icon: 'mdi-store', title: 'Retail Ad Price Manager', path: '/ad-retails' },
        { icon: 'mdi-file-cog-outline', text: 'Layout Manager', path: '/layouts', show: this.$auth.tenant !== 'pricechopper' },
        { icon: 'mdi-note-edit-outline', text: 'Feature Position Manager', path: '/feature-positions', show: true }
      ].filter(navLink => navLink.show)
    },
    videoLinks(){
      return[
      { label: 'Creating an Ad', id: '42e65576-c6be-4167-9b66-2cf92360f5b7'},
      { label: 'Updating an Ad', id: '7f1f57e0-aa15-4d21-8f85-67ebeadd6733'},
      { label: 'Ad Statuses', id: '9269b4ad-eaa8-498d-84b2-5205d82ceab9'},
      { label: 'Ad Notes', id: 'ba9995fe-f46d-4c2a-8b39-df4ac9e3f86c'},
      { label: 'Adding Features', id: '3724a9bb-7499-44fd-bbe5-6984ef77e9c4'},
      { label: 'Adding Items', id: '16202332-bdee-4379-9660-7565e374243b'},
      { label: 'Responsible Parties', id: '5a0b16d0-bc51-4f67-bc9f-4cbfb82934f9'},
      { label: 'Clone an Ad', id: '0a522b1d-ff29-425a-aeef-2300e5ba74e9'},
      { label: 'Shuttle an Ad', id: '9ac02c98-8ef2-4e7b-af64-a5ac419b0295'},
      { label: 'Delete an Ad', id: '29bce228-ae17-4fbd-89d4-f10717fe3870'},
      ]
    },
  },

  watch: {
    modal: {
      immediate: true,
      handler () {
        this.componentKey += 1
        if (!this.modal) this.id=''
      }
    },

  },

  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
      sessionStorage.removeItem('ad_party')
    }
  }
}
</script>
<style scoped>
@import './assets/styles/global.css';
  .active {
    background: #385F73;
    color: #fff !important;
  }
span.registeredTrademark {
  position: relative;
  top: -.3rem;
  font-size: .5rem;
  left: -0.05rem;
}

footer {
  font-size: small;
}
</style>