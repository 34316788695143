export const userAccess = {
  computed: {
    entities () {
      return ['ad_planner']
    },
    adminRoles () {
      return ['promo.admin', 'pce-admin', 'jbg-admin']
    },
    user () {
      return this.$auth.user
    },
    userGroups () {
      return this.$auth.userGroups || []
    },
    userPermissions () {
      return this.$auth.permissions || []
    },
    userRelatedParties () {
      return this.$store.getters.userRelatedParties
    },
    promoCategories () {
      return this.$store.getters.promoCategories
    },
    categoryManagers () {
      return this.$store.getters.categoryManagers
    },
    billingModes () {
      return this.$store.getters.billingModes
    },
    userAdGroupIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'AD_GROUP')
          ? party.id
          : []
      })
    },
    hasReadAccess () {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      return readScopes.every(scope => this.userPermissions.includes(scope))
    },
    hasPortalAccess() {
      return !this.userPermissions.includes('ad_planner:no_access') && this.hasReadAccess
    },
    hasWriteAccess () {
      return this.writePermissions.every(scope => this.userPermissions.includes(scope))
    },
    writePermissions () {
      const actions = ['create', 'read', 'update']
      return this.entities.flatMap(entity => {
        return actions.map(action => `${entity}:${action}`)
      })
    },
    isAdmin () {
      return this.adminRoles.some(role => this.userGroups.includes(role))
    },
    hasStatusAdminAccess () {
      return this.isAdmin || this.userPermissions.includes('ad_planner:status_admin')
    },
    limitAccessByRelatedAdGroups () {
      return !this.isAdmin && this.userGroups.includes('promo.coordinator')
    },
    useAdGroups() {
      // determines whether ads are to be created with ad groups or stores
      return this.$auth.tenant !== 'jbg'
    }
  },
  methods: {
    hasScope (scope) {
      return this.userPermissions.includes(scope)
    }
  }
}