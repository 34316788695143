import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Home',
    // component: () => import('@/views/Home.vue'),
    redirect: '/ads',
    // meta: {
    //   title: 'AIM | Home'
    // }
  },

  {
    path: '/ads',
    name: 'Ad Planner',
    component: () => import('@/views/ad-list.vue'),
    meta: {
      title: 'Ad Planner',
      requiresAuth: true
    }
},

  {
    path: '/features',
    name: 'Ad Features',
    component: () => import('@/views/feature-list.vue'),
    meta: {
      title: 'Ad Planner',
      requiresAuth: true
    }
  },

  {
    path: '/layouts',
    name: 'Layout Manager',
    component: () => import('@/views/layout-list'),
    meta: {
      title: 'Layout Manager',
      requiresAuth: true
    }
  },

  {
    path: '/feature-positions',
    name: 'Feature Position Management',
    component: () => import('@/views/feature-position-management'),
    meta: {
      title: 'Feature Position Management',
      requiresAuth: true
    }
  },
  //REMOVE WHEN AD RETAIL SCREEN IS IMPLEMENTED IN IPRO PORTAL
  // {
  //   path: '/ad-retails',
  //   name: 'Retail Ad Price Manager',
  //   component: () => import('@/views/ad-retails'),
  //   meta: {
  //     title: 'AIM | Retail Ad Price Manager',
  //     requiresAuth: true
  //   }
  // },

  // {
  //   path: '/item-retails',
  //   name: 'Ad Planner Items',
  //   component: () => import('@/views/item-retails.vue'),
  //   meta: {
  //     title: 'AIM | Ad Planner Items',
  //     requiresAuth: true
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  to.meta.referrer = from.name
  if (to.meta) {
    // Use the route's "meta.title" value to assign the page's title
    if (to.meta.title) document.title = to.meta.title
    // For routes requiring authentication
    if (to.meta.requiresAuth === true) {
      return authGuard(to, from, next)
    }
    return next()
  } else {
    return next()
  }
})

export default router
