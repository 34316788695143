import axios from 'axios'
import Auth from '@/auth/auth-token'

class Document {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_DOCUMENT_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.document = base
  }

  async getFile (id) {
    await Auth.setAuth(this.document, {
      audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
    })

    return this.document({
      url: `/doc/${id}/file`,
      method: 'GET',
    })
  }

  async searchDocs (params) {
    await Auth.setAuth(this.document, {
      audience: process.env.VUE_APP_DOCUMENT_AUDIENCE
    })

    return this.document({
      url: `docs/search`,
      method: 'GET',
      params:params
    })
  }
}

export default new Document()