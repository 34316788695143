import Vue from 'vue'
import Vuex from 'vuex'
import { getInstance } from '@/auth/auth0-plugin'

import PromoCategory from '@/axios/promo-category-endpoint'
import Party from '@/axios/party-endpoint'
import kvApi from '@/axios/key-values'
import KeyValues from '@/axios/key-values'
import PartyRelationship from '@/axios/party-relationship-endpoints'
import { sortBy } from 'lodash'

Vue.use(Vuex)

const state = {
  adGroups: [],
  adPages: [],
  userRelatedParties: [],
  userStores: [],
  responsibleParties: [],
  classifications: [],
  snackbar: {},
  promoCategories: [],
  categoryManagers: [],
  billingModes: []
}
const actions = {
  async initParties(context) {
    try {
      const results = await Promise.allSettled([
        context.dispatch('getUserRelatedParties'),
        context.dispatch('getResponsibleParties'),
        context.dispatch('getClassifications'),
        context.dispatch('getAdPages'),
        context.dispatch('getAdGroups'),
        context.dispatch('getPromoCategories'),
        context.dispatch('getCategoryManagers'),
        context.dispatch('getBillingModes')
      ])
      const rejected = results.filter((r) => r.status === 'rejected')
      if (rejected.length > 0) throw rejected
    } catch (err) {
      console.error(err)
    }
  },
  setSnackbar({ commit }, snackbar) {
    snackbar.showing = true
    snackbar.timeout = snackbar.status === 'success' ? 3000 : -1
    snackbar.icon =
      snackbar.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-alert'
    snackbar.color = snackbar.status === 'success' ? 'success' : 'alert'

    if (snackbar.text.toLowerCase().includes("network error")) {
      const regex = / due to.*/i      // Remove 'due to Network Error' from message. Replace with 'Please refresh the page'
      snackbar.text = snackbar.text.replace(regex, ". Please refresh the page.")
      console.error("Unable to connect to backend. Page refresh may be needed.")
    }
    commit('SET_SNACKBAR', snackbar)
  },
  async getAdGroups(context) {
    if (context.getters.adGroups.length > 0) return
    let adGroups = []
    try {
      const { data } = await Party.getAdGroups()
      if (data?.length > 0) {
        adGroups = data.flatMap((party) => {
          if (party.party_type.constant === 'AD_GROUP' && party.attributes?.AD_GROUP_IS_ACTIVE === 'True') {
            return { ...party, party_id: party.id, party_name: party.name }
          }
          return []
        })
      }
      adGroups = sortBy(adGroups, 'party_name')
      return context.commit('commitAdGroups', adGroups)
    } catch (err) {
      console.error(err)
    }
  },
  async getUserRelatedParties(context) {
    const userParties = context.getters.userRelatedParties
    if (userParties.length > 0) return

    const authService = getInstance()
    const { email } = authService.user

    let relatedParties = []
    try {
      const res = await PartyRelationship.getUserProfile(email, true)
      if (res?.data?.length > 0) {
        relatedParties = res.data
      }
      context.commit('commitUserRelatedParties', relatedParties)
      if(authService.tenant === 'jbg') {
        context.dispatch('getUserStores')
      }
    } catch (err) {
      console.error(err)
    }
  },
  async getResponsibleParties(context) {
    const authService = getInstance()
    let parties = []
    if (context.getters.responsible_parties?.length > 0) return
    try {
      const res = await PartyRelationship.getWholesalers()
      parties = res?.data || []
      parties = parties.filter((p) => p.tenant === authService.tenant)
      context.commit('commitResponsibleParties', parties)
    } catch (err) {
      console.error(err)
    }
  },
  async getUserStores(context) {
    const authService = getInstance()
    try {
      const res = await Party.getStores()
      const stores = res?.data || []
      let formattedStores = stores.map(store => {
        return {
          attributes: store.attributes,
          id: store.id,
          name: store.name,
          party_id: store.id,
          party_name: '#' + store.attributes.WHOLESALER_STORE_NUMBER + ' ' + store.name,
          tenant: store.tenant
        }
      })
      if(!authService.userGroups.some(role => ['promo.admin', 'jbg-admin'].includes(role))) {
        const userStoreIds = context.getters.userRelatedParties.filter(party => party.party_type_constant === 'STORE').map(store => store.id)
        formattedStores = formattedStores.filter(store => userStoreIds.includes(store.id))
      }
      formattedStores = sortBy(formattedStores, 'name')
      context.commit('commitUserStores', formattedStores)
    } catch (err) {
      console.error(err)
    }
  },
  async getClassifications(context) {
    try {
      const { data } = await KeyValues.getClassifications()
      context.commit('commitClassifications', data)
    } catch (err) {
      console.error(err)
    }
  },
  async getAdPages(context) {
    try {
      const { data } = await KeyValues.getAdPages()
      context.commit('commitAdPages', data)
    } catch (err) {
      console.error(err)
    }
  },
  async getPromoCategories(context) {
    let promoCategories = []
    try {
      const promo_categories = context.getters.promoCategories
      if (promo_categories.length === 0) {
        let res = await PromoCategory.getList()
        if (res.data.length > 0) {
          promoCategories = res.data
            .filter((c) => {
              return c.name.toLowerCase().includes('lump sum') === false
            })
            .sort((a, b) => a.display_order - b.display_order)
        }
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('commitPromoCategories', promoCategories)
  },
  async getCategoryManagers(context) {
    let categoryManagers = []
    try {
      const res = await Party.getCategoryManagers()
      if (res?.data?.length > 0) {
        categoryManagers = res.data.sort((a, b) => a.name.localeCompare(b.name))
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('commitCategoryManagers', categoryManagers)
  },
  async getBillingModes(context) {
    let billingModes = []
    try {
      const billing_modes = context.getters.billingModes
      if (billing_modes.length === 0) {
        let res = await kvApi.getList('BILLING_MODE', 50, 0)
        if (res?.data?.length > 0) {
          billingModes = res.data.sort((a, b) => a.name.localeCompare(b.name))
        }
      }
    } catch (err) {
      console.error(err)
    }
    return context.commit('commitBillingModes', billingModes)
  }
}

const getters = {
  adGroups (state) {
    return state.adGroups
  },
  userRelatedParties (state) {
    return state.userRelatedParties
  },
  userStores (state) {
    return state.userStores
  },
  responsibleParties (state) {
    return state.responsibleParties
  },
  classifications (state) {
    return state.classifications
  },
  adPages (state) {
    return state.adPages
  },
  promoCategories (state) {
    return state.promoCategories
  },
  categoryManagers () {
    return state.categoryManagers
  },
  billingModes () {
    return state.billingModes
  }
}

const mutations= {
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar
  },
  commitAdGroups (state, payload) {
    state.adGroups = payload
  },
  commitUserRelatedParties (state, payload) {
    state.userRelatedParties = payload
  },
  commitUserStores (state, payload) {
    state.userStores = payload
  },
  commitResponsibleParties (state, payload) {
    state.responsibleParties = payload
  },
  commitClassifications (state, payload) {
    state.classifications = payload
  },
  commitAdPages (state, payload) {
    state.adPages = payload
  },
  commitPromoCategories (state, payload) {
    state.promoCategories = payload
  },
  commitCategoryManagers (state, payload) {
    state.categoryManagers = payload
  },
  commitBillingModes (state, payload) {
    state.billingModes = payload
  },
}


export const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
})
