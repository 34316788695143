import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class BaseApi {
  constructor () {
    const api = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.api = api
  }
  
  async auth (config = {}) {
    await AuthToken.setAuth(this.api)
    return this.api({ ...config })
  }
}

export default BaseApi