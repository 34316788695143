import BaseApi from './base'

class PromoCategory extends BaseApi {
  constructor() {
    super()
  }

  async getList(offset = 0, limit = 50) {
    return await this.auth({
      url: `/promo_categories`,
      method: 'GET',
      params: {
        offset,
        limit
      }
    })
  }
}

export default new PromoCategory()
