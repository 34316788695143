import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PartyRelationship {
  constructor () {
    const base = axios.create({
      // using base customer api url for this, not promos
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.partyRelationship = base
  }

  async getMatch (party1, party2) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party_relationship/related`,
      method: 'GET',
      params: {
        party_1_id: party1.party_1_id,
        party_1_type: party1.party_1_type,
        party_1_email: party1.party_1_email,
        party_2_id: party2.party_2_id,
        party_2_type: party2.party_2_type,
        party_2_email: party2.party_2_email
      }
    })
  }

  async getUpstreamRelationships (data) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: '/party_relationship/upstream',
      method: 'GET',
      params: {
        party_id: data.ad_party_id,
        party_type: 'USER'
      }
    })
  }

  async getDistributionCenter (partyID) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: '/party_relationship/upstream_search',
      method: 'POST',
      data: {
        from_party_ids: [partyID],
        excluded_upstream_types: ['WHOLESALER', 'REGION_GROUP']
      }
    })
  }

  async getUpstreamRelationshipsUser (params) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: '/party_relationship/upstream',
      method: 'GET',
      params: params
    })
  }

async getDownstreamRelationshipsWithType (params) {
  await AuthToken.setAuth(this.partyRelationship, {
    audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
  })

  return this.partyRelationship({
    url: '/party_relationship/downstream_with_type',
    method: 'GET',
    params: params
  })
}

async getDownstreamRelationships (data) {
  await AuthToken.setAuth(this.partyRelationship, {
    audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
  })

  return this.partyRelationship({
    url: '/party_relationship/downstream',
    method: 'GET',
    params: {
      party_id: data.ad_party_id,
      // party_type: data.party_type || null,
      // party_email: data.party_email || null
    }
  })
}

  async getPartyInfo (data) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party/${data}`,
      method: 'GET'
    })
  }

  async getUserProfile (email, include_stores = false) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.partyRelationship({
      url: '/user_profile',
      method: 'GET',
      params: { email, include_stores }
    })
  }
  
  async getWholesalers () {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.partyRelationship({
      url: '/party/search_parties',
      method: 'POST',
      data: { party_types: ['WHOLESALER'] }
    })
  }
}

export default new PartyRelationship()