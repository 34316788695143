import BaseApi from './base'
class KeyValues extends BaseApi {
  constructor() {
    super()
  }

  handleSuccess(response) {
    return response
  }

  async getAdPages() {
    return await this.auth({
      url: 'key_values/AD_PAGES',
      method: 'GET'
    })
  }

  async getPagePositions(adPage) {
    const params = { limit: 200 }
    return await this.auth({
      url: `key_values/PAGE_POSITION_${adPage}`,
      method: 'GET',
      params: params
    })
  }

  async getKeyByConstant(id, params) {
    return await this.auth({
      url: `kv_id/${id}`,
      method: 'GET',
      params: params
    })
  }

  async getAdTypes() {
    const key = 'ADPLANNING.ADTYPE'
    return await this.auth({
      url: `key_values/${key}`,
      method: 'GET'
    })
  }

  async getClassifications() {
    const key = 'ADPLANNING.CLASSIFICATION'
    return await this.auth({
      url: `key_values/${key}`,
      method: 'GET'
    })
  }

  async updateKeyValue(kv) {
    return await this.auth({
      url: `key_value/${kv.id}`,
      method: 'PUT',
      data: kv
    })
  }

  async addKeyValue(kv) {
    return await this.auth({
      url: `key_value`,
      method: 'POST',
      data: kv
    })
  }

  async addKey(key) {
    return await this.auth({
      url: `key`,
      method: 'POST',
      data: key
    })
  }

  async getList(keyId, limit, offset) {
    return await this.auth({
      url: `key_values/${keyId}`,
      method: 'GET',
      params: {
        limit: limit,
        offset: offset
      }
    })
  }
}

export default new KeyValues()