import BaseApi from './base'

class PromoItemGroup extends BaseApi {
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response
  }

  async get (promoItemGroupId) {
    return await this.auth({
      url: `/promo_item_group/${promoItemGroupId}`,
      method: 'GET'
    })
  }
  
  /** are these the same call */
  async getItemGroupsByPromo (promo_id) {
    return await this.auth({
      url: `/promo_item_groups/${promo_id}`,
      method: 'GET'
    })
  }

  async getItemGroupByGroup (promo_item_group_id) {
    return await this.auth({
      url: `/promo_item_groups/${promo_item_group_id}`,
      method: 'GET'
    })
  }
}

export default new PromoItemGroup()