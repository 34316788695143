import BaseApi from './base'
class Status extends BaseApi {
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response}
  
    async getStatuses (entity_id) {

        return await this.auth({
          url: `/statuses/${entity_id}`,
          method: 'GET',
        })
      }
    
    async getStatus (status_id) {

        return await this.auth({
          url: `/status/${status_id}`,
          method: 'GET',
        })
      }
    
    async getWorkflows (entity_type_id) {

        return await this.auth({
          url: `/status/workflows/${entity_type_id}`,
          method: 'GET',
        })
      }

    async getWorkflowValues (workflow_id) {

        return await this.auth({
          url: `/status/workflow_values/${workflow_id}`,
          method: 'GET',
        })
      }

    async getNextStatus (params) {

        return await this.auth({
          url:'/status/workflow/next',
          method: 'GET',
          params:params
        })
    }
 
}
export default new Status()